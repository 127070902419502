import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StatisticsTableMain from '../components/StatisticsTableMain';
import { listTrades } from '../actions/tradeActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import HitRatePieChart from '../components/HitRatePieChart';
import TradesBySymbolBarChart from '../components/TradesBySymbolBarChart';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ProfitBySymbolBarChart from '../components/ProfitBySymbolBarChart';
import TradesByWeekdayBarChart from '../components/TradesByWeekdayBarChart';
import AccountDataTable from '../components/AccountDataTable';
import { Line } from 'react-chartjs-2';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const tradeList = useSelector((state) => state.tradeList);
  const { loading, error, trades } = tradeList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate('/login');
    } else {
      dispatch(listTrades());
    }
  }, [dispatch, userInfo, navigate]);

  const tradingDays = calcNumberOfTradingDays(trades);
  const totalProfit = calcTotalProfit(trades);
  const totalLoss = calcTotalLosses(trades);
  const dollarReturn = totalProfit + totalLoss;
  const profitFactor = (totalProfit / totalLoss) * -1;
  const numberOfTrades = calcNumberOfTrades(trades);
  const numberOfWins = calcNumberOfWins(trades);
  const winRate = calcWinRate(trades);
  const tradesPerDay = (numberOfTrades / tradingDays).toFixed(2);
  const profitPerDay = dollarReturn / tradingDays;

  // get unique entry dates array for balance chart
  const uniqueTradingDates = [];
  trades.reverse().forEach((trade) => {
    if (!uniqueTradingDates.includes(trade.dateEntry)) {
      uniqueTradingDates.push(trade.dateEntry);
    }
  });

  // get profit for each unique entry date
  const profitByDate = [];
  uniqueTradingDates.forEach((date) => {
    let profit = 0;
    trades.forEach((trade) => {
      if (trade.dateEntry == date) {
        profit += trade.dollarProfit;
      }
    });
    profitByDate.push(profit);
  });

  // cumulative profit array for balance chart (profitByDate + previous profit)
  const cumulativeProfit = [];
  profitByDate.forEach((profit, index) => {
    if (index === 0) {
      cumulativeProfit.push(profit);
    } else {
      cumulativeProfit.push(profit + cumulativeProfit[index - 1]);
    }
  });

  // account balance on each trading day
  const accountBalance = [];
  trades.forEach((trade) => {
    accountBalance.push(trade.accountBalance);
  });

  const lineChartData = {
    labels: uniqueTradingDates,
    datasets: [
      {
        label: 'Account Balance',
        data: accountBalance,
        fill: true,
        backgroundColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? 'rgba(255,99,132,0.2)' : 'rgba(54, 162, 235, 0.2)';
        },
        borderColor: function (context) {
          const index = context.dataIndex;
          const value = context.dataset.data[index];
          return value < 0 ? 'rgba(255,99,132,1)' : 'rgba(54, 162, 235, 1)';
        },
        tension: 0.4,
      },
    ],
  };

  let tradesPlaced = Boolean;
  if (trades.length > 0) {
    tradesPlaced = true;
  } else {
    tradesPlaced = false;
  }

  const accountList = [];
  trades.forEach((trade) => {
    if (!accountList.includes(trade.accountName)) {
      accountList.push(trade.accountName);
    }
  });

  const tradeCountByAccount = [];
  accountList.forEach((account) => {
    let count = 0;
    trades.forEach((trade) => {
      if (trade.accountName === account) {
        count++;
      }
    });
    tradeCountByAccount.push(count);
  });

  const profitByAccount = [];
  accountList.forEach((account) => {
    let profit = 0;
    trades.forEach((trade) => {
      if (trade.accountName === account) {
        profit += trade.dollarProfit;
      }
    });
    profitByAccount.push(profit);
  });

  const tradeCountLabels = [];
  trades.forEach((trade) => {
    let index = trades.indexOf(trade) + 1;
    tradeCountLabels.push(index);
  });

  const profitByCountLabels = [];
  trades.forEach((trade) => {
    profitByCountLabels.push(trade.dollarProfit);
  });

  const monthLabels = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  let countJanuary = 0;
  let countFebruary = 0;
  let countMarch = 0;
  let countApril = 0;
  let countMay = 0;
  let countJune = 0;
  let countJuly = 0;
  let countAugust = 0;
  let countSeptember = 0;
  let countOctober = 0;
  let countNovember = 0;
  let countDecember = 0;
  let profitJanuary = 0;
  let profitFebruary = 0;
  let profitMarch = 0;
  let profitApril = 0;
  let profitMay = 0;
  let profitJune = 0;
  let profitJuly = 0;
  let profitAugust = 0;
  let profitSeptember = 0;
  let profitOctober = 0;
  let profitNovember = 0;
  let profitDecember = 0;

  trades.forEach((trade) => {
    if (trade.comment !== 'Investment' && trade.comment !== 'Withdrawal') {
      let date = new Date(trade.dateEntry);
      let month = date.getMonth();
      if (month === 0) {
        countJanuary++;
        profitJanuary = profitJanuary + trade.dollarProfit;
      } else if (month === 1) {
        countFebruary++;
        profitFebruary = profitFebruary + trade.dollarProfit;
      } else if (month === 2) {
        countMarch++;
        profitMarch = profitMarch + trade.dollarProfit;
      } else if (month === 3) {
        countApril++;
        profitApril = profitApril + trade.dollarProfit;
      } else if (month === 4) {
        countMay++;
        profitMay = profitMay + trade.dollarProfit;
      } else if (month === 5) {
        countJune++;
        profitJune = profitJune + trade.dollarProfit;
      } else if (month === 6) {
        countJuly++;
        profitJuly = profitJuly + trade.dollarProfit;
      } else if (month === 7) {
        countAugust++;
        profitAugust = profitAugust + trade.dollarProfit;
      } else if (month === 8) {
        countSeptember++;
        profitSeptember = profitSeptember + trade.dollarProfit;
      } else if (month === 9) {
        countOctober++;
        profitOctober = profitOctober + trade.dollarProfit;
      } else if (month === 10) {
        countNovember++;
        profitNovember = profitNovember + trade.dollarProfit;
      } else if (month === 11) {
        countDecember++;
        profitDecember = profitDecember + trade.dollarProfit;
      }
    }
  });

  const monthlyProfit = [
    profitJanuary,
    profitFebruary,
    profitMarch,
    profitApril,
    profitMay,
    profitJune,
    profitJuly,
    profitAugust,
    profitSeptember,
    profitOctober,
    profitNovember,
    profitDecember,
  ];

  const monthlyCount = [
    countJanuary,
    countFebruary,
    countMarch,
    countApril,
    countMay,
    countJune,
    countJuly,
    countAugust,
    countSeptember,
    countOctober,
    countNovember,
    countDecember,
  ];

  //dictionary of trades with comment
  var investment_amount = 0;
  var withdrawal_amount = 0;
  var investments = [];
  trades.forEach((trade) => {
    if (trade.comment === 'Investment') {
      investments.push(trade);
      investment_amount += trade.investment;
    } else if (trade.comment === 'Withdrawal') {
      investments.push(trade);
      withdrawal_amount += trade.investment;
    }
  });

  var net_investment = investment_amount + withdrawal_amount;

  const currentBalance = net_investment + dollarReturn;

  const accountData = {
    //investorID: userInfo.investorID,
    amountInvested: investment_amount,
    amountWithdrawn: withdrawal_amount,
    accountBalance: currentBalance,
    totalReturnPercentage: ((dollarReturn / investment_amount) * 100).toFixed(
      2
    ),
    totalPnl: dollarReturn.toFixed(2),
    netBalance: net_investment + dollarReturn * (1 - userInfo.profitSplit),
  };

  const tradeStatistics = {
    totalPnl: dollarReturn.toFixed(2),
    averageGainPerDay: profitPerDay.toFixed(2),
    numberTrades: numberOfTrades,
    profitFactor: profitFactor.toFixed(2),
    winRate: winRate.toFixed(2),
    percentageReturn: ((dollarReturn / investment_amount) * 100).toFixed(2),
    numberOfWins: numberOfWins,
    numberOfLosses: numberOfTrades - numberOfWins,

    // maxDrawdown: calcMaxDrawdown(trades),
    // accountPeak: calcAccountPeak(trades),
  };

  return (
    <div className='my-4 mx-5'>
      {/* <TickerWidget /> */}
      <h1 className='table-header'>Statistics</h1>
      <p className='table-header-subtext'>
        (Updated at the end of every trading day by 23:00 GMT)
      </p>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message />
      ) : (
        <>
          <Container className='my-4'>
            <Row>
              <Col xs={12} md={9}>
                <StatisticsTableMain tradeData={tradeStatistics} />
              </Col>
              <Col xs={12} md={3}>
                <AccountDataTable accountData={accountData} />
              </Col>
            </Row>
          </Container>

          <Container className='my-4'>
            <Row className='justify-content-center py-3 align-items-center'>
              <Card className='my-4 chart-card'>
                <Line data={lineChartData} />
              </Card>
            </Row>

            <Row className='justify-content-center py-3'>
              <Col xs={12} md={6}>
                <Card className='my-4 chart-card'>
                  <TradesByWeekdayBarChart
                    labels={monthLabels}
                    data={monthlyCount}
                    title='# of Days Traded by Month'
                  />
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card className='my-4 chart-card'>
                  <ProfitBySymbolBarChart
                    title='Profit/Loss by Month'
                    labels={monthLabels}
                    data={monthlyProfit}
                  />
                </Card>
              </Col>
            </Row>
            {loading ? null : tradesPlaced ? null : (
              <Message variant='warning'>
                No trading days since investment.
              </Message>
            )}
          </Container>
        </>
      )}
    </div>
  );
};

export default Dashboard;

function calcAverageTrade(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    profitArray.push(trade.dollarProfit);
  });
  let averageTrade =
    profitArray.reduce((a, b) => a + b, 0) / profitArray.length;
  return averageTrade;
}

function calcAverageWinningTrade(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit > 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  let averageWinningTrade =
    profitArray.reduce((a, b) => a + b, 0) / profitArray.length;
  return averageWinningTrade;
}

function calcAverageLosingTrade(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit < 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  let averageLosingTrade =
    profitArray.reduce((a, b) => a + b, 0) / profitArray.length;
  return averageLosingTrade;
}

function calcBiggestWinner(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit > 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  let biggestWinner = Math.max(...profitArray);
  return biggestWinner;
}

function calcBiggestLoser(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit < 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  let biggestLoser = Math.min(...profitArray);
  return biggestLoser;
}

function calcNumberOfTrades(trades) {
  let count = 0;
  trades.forEach((trade) => {
    if (trade.comment !== 'Investment' && trade.comment !== 'Withdrawal')
      count++;
  });
  return count;
}

function calcNumberOfWins(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit > 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  return profitArray.length;
}

function calcNumberOfLosses(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit < 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  return profitArray.length;
}

function calcWinRate(trades) {
  return (calcNumberOfWins(trades) / calcNumberOfTrades(trades)) * 100;
}

function calcTotalQuantityTraded(trades) {
  let quantityArray = [];
  trades.forEach((trade) => {
    quantityArray.push(trade.quantity);
  });
  let totalQuantityTraded = quantityArray.reduce((a, b) => a + b, 0);
  return totalQuantityTraded;
}

function calcTotalProfit(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit > 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  let totalProfit = profitArray.reduce((a, b) => a + b, 0);
  return totalProfit;
}

function calcTotalLosses(trades) {
  let profitArray = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit < 0) {
      profitArray.push(trade.dollarProfit);
    }
  });
  let totalLosses = profitArray.reduce((a, b) => a + b, 0);
  return totalLosses;
}

function calcTotalDollarReturn(trades) {
  return calcTotalProfit(trades) - calcTotalLosses(trades);
}

function calcTotalPercentageReturn(trades, accountBalance) {
  return calcTotalDollarReturn(trades) / accountBalance;
}

function calcAverageRiskPerTrade(trades) {
  let totalRisk = 0;
  trades.forEach((trade) => {
    totalRisk += trade.dollarRisk;
  });
  return totalRisk / calcNumberOfTrades(trades);
}

function calcAveragePercentageReturn(trades) {
  let percentageReturn = 0;
  trades.forEach((trade) => {
    percentageReturn += trade.percentageReturn;
  });
  return percentageReturn / calcNumberOfTrades(trades);
}

function calcAverageRiskPercentage(trades) {
  let percentageRisk = 0;
  trades.forEach((trade) => {
    percentageRisk += trade.percentageRisk;
  });
  return percentageRisk / calcNumberOfTrades(trades);
}

function calcAverageRiskReward(trades) {
  let riskReward = 0;
  trades.forEach((trade) => {
    riskReward += trade.riskReward;
  });
  return riskReward / calcNumberOfTrades(trades);
}

function calcAverageR(trades) {
  let averageR = 0;
  trades.forEach((trade) => {
    if (trade.dollarProfit > 0) {
      averageR += trade.returnedR;
    }
  });
  return averageR / calcNumberOfTrades(trades);
}

function calcTotalR(trades) {
  let totalR = 0;
  trades.forEach((trade) => {
    totalR += trade.returnedR;
  });
  return totalR;
}

function calcNumberOfTradingDays(trades) {
  let entryDays = [];
  trades.forEach((trade) => {
    entryDays.push(trade.dateEntry);
  });
  let uniqueDays = new Set(entryDays).size;
  return uniqueDays;
}

function calcAverageProfitPerDay(trades) {
  return calcTotalProfit(trades) / calcNumberOfTradingDays(trades);
}

function calcEstimatedProfitPerWeek(trades) {
  return calcAverageProfitPerDay(trades) * 5;
}

function calcLongTradesQuantity(trades) {
  let longQuantity = 0;
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].direction === 'Long') {
      longQuantity += trades[i].quantity;
    }
  }
  return longQuantity;
}

function calcLongTradesWinnerQuantity(trades) {
  let longWinnerQuantity = 0;
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].direction === 'Long' && trades[i].dollarProfit > 0) {
      longWinnerQuantity += trades[i].quantity;
    }
  }
  return longWinnerQuantity;
}

function calcShortTradesWinnerQuantity(trades) {
  let shortWinnerQuantity = 0;
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].direction === 'Short' && trades[i].dollarProfit > 0) {
      shortWinnerQuantity += trades[i].quantity;
    }
  }
  return shortWinnerQuantity;
}

function calcMaxConsecutiveWinners(trades) {
  let consecutiveWinners = 0;
  let winnersArray = [];
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].dollarProfit > 0) {
      consecutiveWinners++;
      winnersArray.push(consecutiveWinners);
    } else {
      consecutiveWinners = 0;
    }
  }
  let maxConsecutiveWinners = Math.max(...winnersArray);
  return maxConsecutiveWinners;
}

function calcMaxConsecutiveLosses(trades) {
  let consecutiveLosses = 0;
  let losersArray = [];
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].dollarProfit < 0) {
      consecutiveLosses++;
      losersArray.push(consecutiveLosses);
    } else {
      consecutiveLosses = 0;
    }
  }
  let maxConsecutiveLosses = Math.max(...losersArray);
  return maxConsecutiveLosses;
}

function calcAverageLosingTradeDuration(trades) {
  let losingTrades = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit <= 0) {
      losingTrades.push(trade);
    }
  });
  if (losingTrades.length === 1) {
    return losingTrades[0].duration;
  } else {
    let losingTradesDuration = 0;
    losingTrades.forEach((trade) => {
      losingTradesDuration += trade.duration;
    });
    return losingTradesDuration / losingTrades.length;
  }
}

function calcAverageWinningTradeDuration(trades) {
  let winningTrades = [];
  trades.forEach((trade) => {
    if (trade.dollarProfit > 0) {
      winningTrades.push(trade);
    }
  });
  if (winningTrades.length === 1) {
    return winningTrades[0].duration;
  } else {
    let winningTradesDuration = 0;
    winningTrades.forEach((trade) => {
      winningTradesDuration += trade.duration;
    });
    return winningTradesDuration / winningTrades.length;
  }
}

function convertMsToTime(duration) {
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));
  return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's';
}

function calcMaxDrawdown(trades) {
  let maxDrawdown = 0;
  let currentDrawdown = 0;
  for (let i = 0; i < trades.length; i++) {
    if (trades[i].dollarProfit > 0) {
      currentDrawdown = 0;
    } else {
      currentDrawdown += trades[i].dollarProfit;
    }
    if (currentDrawdown < maxDrawdown) {
      maxDrawdown = currentDrawdown;
    }
  }
  return maxDrawdown;
}

function calcAccountPeak(trades) {
  let balanceHistoryArray = [];
  let cumulativeBalanceArray = [];
  let accountPeak = 0;
  let cumulativeBalance = 0;
  trades.forEach((trade) => {
    balanceHistoryArray.push(trade.dollarProfit);
  });

  for (let i = 0; i < balanceHistoryArray.length; i++) {
    cumulativeBalance = balanceHistoryArray[i] + cumulativeBalanceArray[i - 1];
    cumulativeBalanceArray.push(cumulativeBalance);
    if (cumulativeBalance > accountPeak) {
      accountPeak = cumulativeBalance;
    }
  }

  console.log(accountPeak);

  console.log(balanceHistoryArray);
  console.log(cumulativeBalanceArray);
  return accountPeak;
}

function calcTotalCommissions(trades) {
  let totalCommission = 0;
  trades.forEach((trade) => {
    totalCommission += parseFloat(trade.commissions);
  });
  return totalCommission;
}

function calcTotalSwap(trades) {
  let totalSwap = 0;
  trades.forEach((trade) => {
    totalSwap += parseFloat(trade.swap);
  });
  return totalSwap;
}

// get hours frim entry time
function getHoursFromEntryTime(entryTime) {
  let hours = entryTime.slice(0, 2);
  return hours;
}
